// eslint-disable-next-line import/no-cycle
import requestService from '../requestService';

const API_KEY = '5bc5d2947860438c2055ab92fafdf4c8';
const ENDPOINT_URL = '/np/request';
const NOVAPOCHTA_URL = 'https://api.novaposhta.ua/v2.0/json/';

export default {
  async searchByTTN({ ttn, phone }) {
    // console.log(ttn);
    // console.log(phone);
    const document = {};
    if (phone) {
      document.Phone = phone;
    }
    const response = await requestService.post(
      ENDPOINT_URL,
      {
        apiKey: API_KEY,
        modelName: 'TrackingDocument',
        calledMethod: 'getStatusDocuments',
        methodProperties: {
          Documents: [
            {
              DocumentNumber: ttn,
              ...document,
            },
          ],
        },
      },
    );
    console.log(response)
    return response?.data?.data;
  },
  async getAllCities() {
    const response = await requestService.post(
      ENDPOINT_URL,
      {
        apiKey: API_KEY,
        modelName: 'Address',
        calledMethod: 'getCities',
      },
    );
    return response?.data?.data;
  },
  async getAllSettlements(query = '', page = 1, limit = 50) {
    const response = await requestService.post(
      ENDPOINT_URL,
      {
        apiKey: API_KEY,
        modelName: 'Address',
        calledMethod: 'getSettlements',
        methodProperties: {
          Page : page,
          FindByString : query,
          Limit : limit
        },
      },
    );
    return response?.data?.data;
  },
  async getAllStreets(city = '', query = '', page = 1, limit = 50) {
    const response = await requestService.post(
      ENDPOINT_URL,
      {
        apiKey: API_KEY,
        modelName: 'Address',
        calledMethod: 'getSettlementStreets',
        methodProperties: {
          SettlementRef: city,
          Page : page,
          FindByString : query,
          Limit : limit
        },
      },
    );
    return response?.data?.data;
  },
  async getWarehousesByCityRef(cityRef) {
    if (cityRef) {
      const response = await requestService.post(
        ENDPOINT_URL,
        {
          apiKey: API_KEY,
          modelName: 'Address',
          calledMethod: 'getWarehouses',
          methodProperties: {
            CityRef: cityRef,
          },
        },
      );
      return response?.data?.data;
    }
    else return []
  },
  async getWarehouse(ref) {
    if (ref) {
      const response = await requestService.post(
        ENDPOINT_URL,
        {
          apiKey: API_KEY,
          modelName: 'AddressGeneral',
          calledMethod: 'getWarehouses',
          methodProperties: {
            Ref: ref,
          },
        },
      );
      return response?.data?.data;
    }
    else {
      return []
    }
  },
  async getCitiesByRegionRef(regionRef) {
    const response = await requestService.post(
      ENDPOINT_URL,
      {
        apiKey: API_KEY,
        modelName: 'Address',
        calledMethod: 'getCities',
        methodProperties: {
          RegionRef: regionRef,
        },
      },
    );
    return response?.data?.data;
  },
  async getDataByTTN(ttn) {
    const response = await requestService.post(
      ENDPOINT_URL,
      {
        apiKey: API_KEY,
        modelName: 'TrackingDocument',
        calledMethod: 'getStatusDocuments',
        methodProperties: {
          Documents: [
            {
              DocumentNumber: ttn,
              Phone: '+380937010009',
            },
          ],
        },
      },
    );
    return response?.data?.data;
  },
  async postCreateTTN(data) {
    const response = await requestService.postCustom(NOVAPOCHTA_URL, JSON.stringify(data), false);
    return response;
  },
  async getCounterpartyList() {
    const response = await requestService.get('/np/counterparties/list', {}, {}, false);
    return response.data.object;
  },
  async daleyCounterpartyItem(uuid) {
    const response = await requestService.delete(`/np/counterparties/delete/${uuid}`);
    return response.data.object;
  },
  async setDefaultCounterparty(uuid) {
    const response = await requestService.patch(`/np/counterparties/default/${uuid}`);
    return response.data.object;
  },
  async updateCounterpartyItem(data) {
    const response = await requestService.post('/np/counterparties/update', data);
    return response.data.object;
  },
  async addCounterpartyItem(data) {
    const response = await requestService.post('/np/counterparties/add', data);
    return response;
  },
};
