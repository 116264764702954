<template>
  <transition name="main-loader">
    <div class="loader-wrapper" v-show="isLoading">
      <!-- <VProgressCircular
        :size="70"
        :width="7"
        color="#4FAE3B"
        indeterminate

      ></VProgressCircular> -->
      <div class="loader">
      <transition name="img-loader">
      <VRow class="mb-3" no-gutters v-show="isLoading" >
       <img  class="mx-auto" src="@/assets/images/logo-dark.svg" alt="" />
      </VRow>
      </transition>
        <VSlideYReverseTransition>
          <VRow no-gutters v-show="isLoading">
            <VProgressLinear
              rounded
              height="3"
              color="#4FAE3B"
              indeterminate
            >
            </VProgressLinear>
          </VRow>
        </VSlideYReverseTransition>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import * as getterTypes from '@/store/modules/loader/types/getters';

export default {
  name: 'Loader',
  computed: {
    ...mapGetters('loader', {
      isLoading: getterTypes.IS_LOADING,
    }),
  },
};
</script>

<style lang="scss"> 
.loader-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100vh;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 200;
  overflow: hidden;




}

.loader{
width: 20vh;
img{
  width: 80%;
}
}
.main-loader-leave-active {
  transition: opacity .5s;
}
.main-loader-leave-to {
  opacity: 0;
}
.img-loader-enter-active,
.img-loader-leave-active {
  transition:  .4s;
}
.img-loader-leave-to {
    opacity: 0;
  transform: translateY(-30%);
}
.img-loader-enter {
  opacity: 0;
  transform: translateY(-10%);
}
.img-loader-enter-to {
  opacity: 1;
  transform: translateY(0);
}
</style>
