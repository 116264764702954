// eslint-disable-next-line import/no-cycle
import novaPochtaService from '../../../services/request/novaPochta/novaPochtaService';
import * as actions from './types/actions';
import * as mutations from './types/mutations';

export default {
  [actions.FETCH_CITIES]: async ({ commit,state }) => {
    if(!state.cities.length){
      const cities = await novaPochtaService.getAllCities();
      commit(mutations.SET_CITIES, cities);
    }
  },
  [actions.FETCH_WAREHOUSES]: async ({ commit }, cityRef) => {
    const warehouses = await novaPochtaService.getWarehousesByCityRef(cityRef);
    commit(mutations.SET_WAREHOUSES, warehouses);
  },
  [actions.FETCH_CITIES_BY_REGION]: async ({ commit }, regionRef) => {
    const cities = await novaPochtaService.getCitiesByRegionRef(regionRef);
    commit(mutations.SET_CITIES, cities);
  },
  [actions.FETCH_WAREHOUSE_BY_REF]: async ({ commit }, ref) => {
    const warehouse = await novaPochtaService.getWarehouse(ref);
    commit(mutations.SET_WAREHOUSES, warehouse);
  },
};
