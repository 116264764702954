var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ns-badge__wrapper"},[_c('div',{staticClass:"ns-badge__inner",class:[
        {
          border: _vm.nsBorder === true,
          'no-content': !_vm.nsContent,
          sm: _vm.nsSize === 'sm',
          xs: _vm.nsSize === 'xs',
          'd-none': !_vm.showBadge
        },
        _vm.nsColor.indexOf('#') ? _vm.nsColor : ''
      ],style:(!_vm.nsColor.indexOf('#') ? `background:${_vm.nsColor}` : '')},[_vm._v(" "+_vm._s(_vm.nsContent)+" ")]),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }