<template>

  
  <svg width="18" height="14" viewBox="0 0 18 14" fill="#F8F8F8" xmlns="http://www.w3.org/2000/svg">
<path d="M1.05882 6.46154H4.23529C4.81765 6.46154 5.29412 5.97692 5.29412 5.38462V1.07692C5.29412 0.484615 4.81765 0 4.23529 0H1.05882C0.476471 0 0 0.484615 0 1.07692V5.38462C0 5.97692 0.476471 6.46154 1.05882 6.46154ZM1.05882 14H4.23529C4.81765 14 5.29412 13.5154 5.29412 12.9231V8.61539C5.29412 8.02308 4.81765 7.53846 4.23529 7.53846H1.05882C0.476471 7.53846 0 8.02308 0 8.61539V12.9231C0 13.5154 0.476471 14 1.05882 14ZM7.41177 14H10.5882C11.1706 14 11.6471 13.5154 11.6471 12.9231V8.61539C11.6471 8.02308 11.1706 7.53846 10.5882 7.53846H7.41177C6.82941 7.53846 6.35294 8.02308 6.35294 8.61539V12.9231C6.35294 13.5154 6.82941 14 7.41177 14ZM13.7647 14H16.9412C17.5235 14 18 13.5154 18 12.9231V8.61539C18 8.02308 17.5235 7.53846 16.9412 7.53846H13.7647C13.1824 7.53846 12.7059 8.02308 12.7059 8.61539V12.9231C12.7059 13.5154 13.1824 14 13.7647 14ZM7.41177 6.46154H10.5882C11.1706 6.46154 11.6471 5.97692 11.6471 5.38462V1.07692C11.6471 0.484615 11.1706 0 10.5882 0H7.41177C6.82941 0 6.35294 0.484615 6.35294 1.07692V5.38462C6.35294 5.97692 6.82941 6.46154 7.41177 6.46154ZM12.7059 1.07692V5.38462C12.7059 5.97692 13.1824 6.46154 13.7647 6.46154H16.9412C17.5235 6.46154 18 5.97692 18 5.38462V1.07692C18 0.484615 17.5235 0 16.9412 0H13.7647C13.1824 0 12.7059 0.484615 12.7059 1.07692Z" fill="#676767"/>
</svg>

</template>

<script>
import iconMixin from './iconMixin';

export default {
  mixins: [iconMixin],
  name: 'CardList',
};
</script>

<style scoped>

</style>
