<template>
  <VMenu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" style="z-index:1000">
        <span class="d-flex align-center">
          <div class="d-flex align-center ml-2" >
            <img width="28" class="mr-2 rounded-circle userAvatarDrop" :src="getUserProperty('image')"
                 v-if="getUserProperty('image')">
            <img width="28" class="mr-2 rounded-circle userAvatarDrop" :src="user.path_img"
                 v-else-if="user.path_img">
            <VIcon  class="mr-2" size="28" v-else>mdi-account-circle</VIcon>
            <span style="color:#757575; font-weight: 500;">
              {{ !$vuetify.breakpoint.smAndDown ? getUserProperty('name') : '' }}
            </span>
          </div>
          <VIcon
          color="grey"
            v-if="attrs['aria-expanded'] === 'false' && !$vuetify.breakpoint.mobile"
          >mdi-menu-down</VIcon>
          <VIcon
          color="grey"
            :class="!$vuetify.breakpoint.smAndDown ? '' : ''"
            v-else-if="attrs['aria-expanded'] === 'true' && !$vuetify.breakpoint.mobile"
          >mdi-menu-up</VIcon>
        </span>
      </span>
    </template>
    <VCard>
      <VList>
        <VListItem>
          <VListItemAvatar>
            <img :src="getUserProperty('image')" v-if="getUserProperty('image')">
            <img :src="user.path_img" v-else-if="user.path_img">
            <Avatar :name="getUserProperty('type')" v-else/>
          </VListItemAvatar>
          <VListItemContent>
            <VListItemTitle>{{ getUserProperty('name') }}</VListItemTitle>
            <VListItemSubtitle>{{ getUserProperty('email') }}</VListItemSubtitle>
          </VListItemContent>
        </VListItem>

        <VDivider/>

        <VListItem @click="onSettings" style="display:none">
          <VListItemTitle>
            <VIcon left>mdi-cog</VIcon>
            {{$t('profile.settings')}}
          </VListItemTitle>
        </VListItem>
        <VListItem @click="$router.push($localize({name:'profile'}))">
          <VListItemTitle>
            <VIcon left>mdi-cog</VIcon>
              {{$t('profile.settings')}}
          </VListItemTitle>
        </VListItem>
        <VListItem @click="onLogOut">
          <VListItemTitle>
            <VIcon left>mdi-logout</VIcon>
              {{$t('btn.log_out')}}
          </VListItemTitle>
        </VListItem>
      </VList>
    </VCard>
  </VMenu>
</template>

<script>
import Avatar from './Avatar.vue';
import user from '../../mixins/user';
import language from '../../mixins/language';
import usersService from "../../services/request/users/usersService";

export default {
  name: 'UserDropdown',
  components: {
    Avatar,
  },
  data: () => ({
    user: {}
  }),
  mixins: [user, language],
  methods: {
    async onLogOut() {
      await this.logOut();
      localStorage.removeItem('opened')
      localStorage.removeItem('actived')
      this.$router.push(this.$localize({name: 'login'}));
    },
    onSettings() {
      this.$emit('settings');
    },
  },
  async mounted() {
    // const userInfo = await usersService.getUserUUID(this.getUser.uuid);
    this.user = this.getUser;
    // this.user = {
    //   ...userInfo
    // };
  },
};
</script>

<style scoped>
.userAvatarDrop {
  width: 28px;
  height: 28px;
  object-fit: cover;
  border:.7px solid #c2c2c2;
}
</style>
