import _ from "lodash";

// eslint-disable-next-line import/prefer-default-export
export const uniqueByUUID = array => _.uniqBy(array, "uuid");
// eslint-disable-next-line consistent-return
export function noEngTextPasted(e, isNumber = false) {
  const value = [...e.clipboardData.getData("Text")];
  const regex = isNumber ? /^[+0-9 ]+$/ : /^[.,а-яА-ЯёЁіІэЭєЄ ]+$/;
  value.forEach(char => {
    if (regex.test(char)) {
      this.onEngText = true;
    } else {
      e.preventDefault();
      this.onEngText = false;
    }
  });
}

export function noEngText(event,isKeyDown = true) {
  let char = ''
  if(event.keyCode){
    char = String.fromCharCode(event.keyCode);
  }else{
    char = event
  }

  if (/^[а-яА-ЯёЁіІэЭєЄ ]+$/.test(char)) {
    this.onEngText = true;
  } else {
    if(isKeyDown){
      event.preventDefault()
    }
    this.onEngText = false;
  }
}
export function noEngTextNumber(e) {
  const char = String.fromCharCode(e.keyCode);
  if (/^[.,а-яА-ЯёЁіІэЭєЄ0-9 ]+$/.test(char)) {
    this.onEngText = true;
  } else {
    e.preventDefault();
    this.onEngText = false;
  }
}
export function noEngTextSymbols(e) {
  const char = String.fromCharCode(e.keyCode);
  if (/[^a-zA-Z]+$/.test(char)) return true;
  e.preventDefault();
}
export function onlyNumber(e,dot) {
  const char = String.fromCharCode(e.keyCode);
  const regex = dot ? /[.0-9]+$/ : /[0-9]+$/
  if (regex.test(char)) return true;
  e.preventDefault();
}
// if (this.settingDialog === 'supply') {} else if(this.settingDialog === 'crm') {}
