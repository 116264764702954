import * as actions from './types/actions';
import * as mutations from './types/mutations';

export default {
  [actions.SET_SUPPLY_PRODUCT]: async ({ commit }, product) => {
    commit(mutations.SET_SUPPLY_PRODUCT, product);
  },
  [actions.REMOVE_SUPPLY_PRODUCT]: async ({ commit }, uuid) => {
    commit(mutations.REMOVE_SUPPLY_PRODUCT, uuid);
  },
  [actions.CLEAR_CART]: ({ commit }) => {
    commit(mutations.CLEAR_CART);
  },
  [actions.OPEN_CART_DIALOG]: ({ commit }) => {
    commit(mutations.OPEN_CART_DIALOG);
  },
  [actions.CLOSE_CART_DIALOG]: ({ commit }) => {
    commit(mutations.CLOSE_CART_DIALOG);
  },
};
