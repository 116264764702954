export default {
  LOG_IN: 'Войти',
  REGISTER: 'Зарегестрироваться',
  REGISTRATION: 'Регистрация',
  AUTHORIZATION: 'Авторизация',
  EMAIL_MUST_BE_VALID: 'E-mail должен быть валидным!',
  EMAIL: 'E-mail',
  NAME: 'Имя',
  SURNAME: 'Фамилия',
  PHONE_NUMBER: 'Номер телефона',
  PASSWORD: 'Пароль',
  DONT_HAVE_AN_ACCOUNT: 'Нет аккаунта?',
  FORGOT_PASSWORD: 'Забыли пароль?',
  ALREADY_REGISTERED: 'Уже зарегестрированны?',
  WELCOME: 'Добро пожаловать!',
  SUCCESSFULLY_REGISTERED: 'Вы успешно зарегестрированы!',
  NEED_TO_ACTIVE_ACCOUNT: 'Чтобы продолжить, вам нужно активировать свой аккаунт',

  isRequired(constant) {
    return `${constant} обязательное поле для заполнения!`;
  },
};
