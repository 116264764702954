<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.92651 17.5052C6.92651 16.5292 6.92651 15.5532 6.92651 14.5772C6.92651 14.2624
       7.05245 14.1049 7.33581 13.979C8.97299 13.2863 10.5787 12.5622 12.2159 11.8696C12.7196
        11.6492 12.94 11.8066 12.94 12.3418C12.94 14.2938 12.94 16.2459 12.9715 18.1979C12.9715
         18.4812 12.877 18.6701 12.5937 18.7646C10.925 19.4573 9.28783 20.1814 7.65065
          20.9055C7.1469 21.1259 6.92651 20.9685 6.92651 20.4648C6.92651 19.4887 6.92651
          18.5127 6.92651 17.5052Z"/>
    <path d="M6.01349 17.5367C6.01349
     18.5127 6.01349 19.4887 6.01349
      20.4962C6.01349 20.9685 5.7931 21.1259 5.32084 20.937C3.68366
       20.2443 2.04648 19.5202 0.377811 18.8276C0.125937 18.7016 0 18.5442
       0 18.2608C0 16.3088 0 14.3253 0 12.3733C0 11.8695 0.22039 11.7121 0.692654
       11.901C2.32984 12.5937 3.96702 13.3178 5.63568 14.0105C5.91904 14.1364 6.01349
       14.2938 6.01349 14.6087C6.01349 15.5847 6.01349 16.5607 6.01349 17.5367Z"/>
    <path d="M6.39135 13.3493C6.2969 13.3178 6.17096 13.2863 6.07651 13.2234C4.34487
     12.4677 2.61324 11.6806 0.881605 10.925C0.6927 10.8306 0.535278 10.7361 0.535278
      10.4842C0.535278 10.2638 0.6927 10.1694 0.881605 10.0749C2.64472 9.28783 4.37636
       8.50072 6.13948 7.7451C6.2969 7.68213 6.54877 7.68213 6.70619 7.7451C8.46931
       8.50072 10.2009 9.28783 11.9641 10.0435C12.153 10.1379 12.3104 10.2324 12.3104
        10.4527C12.3104 10.6731 12.153 10.7991 11.9641 10.862C10.2009 11.6492 8.46931
        12.4363 6.70619 13.1919C6.58026 13.2863 6.4858 13.3178 6.39135 13.3493Z"/>
    <path d="M3.17993 2.95952H5.10047C5.16344 2.95952
    5.19493 2.92804 5.19493 2.86507V0.535232C5.19493 0.251874
    5.4468 0 5.73016 0H7.11547C7.39883 0 7.6507 0.251874 7.6507
    0.535232V2.86507C7.6507 2.92804 7.68218 2.95952 7.74515 2.95952H9.66569C9.76014 2.95952
     9.79163 3.05397 9.72866 3.11694C8.6582 4.18741 7.55625 5.28936 6.48578 6.35982C6.4543
      6.3913 6.39133 6.3913 6.35984 6.35982C5.32086 5.32084 4.21891 4.21889 3.11697
      3.14843C3.08548 3.05397 3.11697 2.95952 3.17993 2.95952Z"/>
  </svg>

</template>

<script>
import iconMixin from './iconMixin';

export default {
  mixins: [iconMixin],
  name: 'PackageDown',
};
</script>

<style scoped>

</style>
