import * as mutationTypes from './types/mutations';
import { userNormalizer } from '../../../services/normalizer/userNormalizer';

export default {
  [mutationTypes.SET_USER_DATA]: (state, userData) => {
    state.user = userNormalizer(userData);
    localStorage.setItem('userData', JSON.stringify(userNormalizer(userData)));
  },
  [mutationTypes.LOG_OUT]: (state) => {
    state.user = null;
    localStorage.setItem('userData', null);
  },
  [mutationTypes.SET_MENU_POSITION]: (state, status) => {
    state.menuPosition = status;
    localStorage.setItem('menuPosition', status ? 1 : 0);
  },
};
