import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line import/no-cycle
import auth from './modules/auth';
import language from './modules/language';
import notifications from './modules/notifications';
import loader from './modules/loader';
// eslint-disable-next-line import/no-cycle
import crm from './modules/crm';
// eslint-disable-next-line import/no-cycle
import novaPochta from './modules/novaPochta';
import supplyCart from './modules/supplyCart';
import sizeUI from './modules/sizeUI';
// eslint-disable-next-line import/no-cycle
import moveList from './modules/moveList';
// eslint-disable-next-line import/no-cycle
import requestsAmount from './modules/requestsAmount'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    language,
    notifications,
    loader,
    crm,
    novaPochta,
    supplyCart,
    sizeUI,
    moveList,
    requestsAmount,
  },
});
