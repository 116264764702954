<template>
  <VDialog v-model="visibility" max-width="800px" content-class="cross__dialog">
    <VCard>
      <div class="cross__inner">
        <VBtn
          fab
          small
          color="white"
          elevation="0"
          @click="visibility = false"
        >
          <v-icon>mdi-close</v-icon>
        </VBtn>
      </div>
      <VCardTitle>
        <VBtn text color="primary" @click="copyToCLipboard">
          <v-icon left>
            mdi-history
          </v-icon>
          {{$t('history.item_history')}} {{ item.ns_code }}
        </VBtn>
      </VCardTitle>
      <VCardText>
        <div class="my-3 mb-5">
          <ItemInfo
            v-if="item"
            :item="item.product_details"
            :sector="sector"
            :cell="cell"
            :user="user"
          />
        </div>
        <!-- <VDivider /> -->
        <div>
          <HistoryInfo :historyInfo="historyInfo" />
        </div>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import notifications from "../../mixins/notifications";
import depotService from "../../services/request/depot/depotService";
import usersService from "../../services/request/users/usersService";
import HistoryInfo from "./searchInfo/historyInfo.vue";
import ItemInfo from "./searchInfo/ItemInfo.vue";

export default {
  components: {
    ItemInfo,
    HistoryInfo
  },
  data: () => ({
    user: [],
    item: [],
    sector: "",
    cell: "",
    historyInfo: []
  }),
  mixins: [notifications],
  props: {
    visible: {
      required: true
    },
    itemCod: {
      require: true
    }
  },

  created() {
    this.getItem();
    this.getHistory();
  },
  methods: {
      async copyToCLipboard() {
      try {
        await navigator.clipboard.writeText(this.item.ns_code);
        this.setSuccessNotification(this.$t('profile.copied_to_clipboard'));
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async getHistory() {
      try {
        this.historyInfo = await depotService.getHistory(this.itemCod);
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async getItem() {
      try {
        this.item = await depotService.getItemInfo(this.itemCod);
        this.getUserInfo(this.item.uuid_user);
        this.sector = this.item.sector_details.name;
        this.cell = this.item.cell_details.name;
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async getUserInfo(event) {
      try {
        this.user = await usersService.getUserByUUID(event);
        if (this.user) {
          this.user = this.user[0];
        } else {
          this.user = null;
        }
      } catch (e) {
        this.setErrorNotification(e);
      }
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    }
  }
};
</script>
