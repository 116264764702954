// eslint-disable-next-line import/no-cycle
import requestService from '../requestService';

const prefix = '/deports';
const sectorPrefix = `${prefix}/sectors`;
const cellsPrefix = `${sectorPrefix}/cells`;

export default {
  async addDepot(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/add`, payload);
  },
  async deleteDepot(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/delete/${uuid}`);
  },
  async addSector(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${sectorPrefix}/add`, payload);
  },
  async deleteSector(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${sectorPrefix}/delete/${uuid}`);
  },
  async updateSector(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${sectorPrefix}/update`, payload);
  },
  async updateDepot(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/update`, payload);
  },
  async addCell(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${cellsPrefix}/add`, payload);
  },
  async deleteCell(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${cellsPrefix}/delete/${uuid}`);
  },
  async updateCell(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${cellsPrefix}/update`, payload,{},false);
  },
  async getDepotsList(params) {
    const response = await requestService.get(`${prefix}/list`, params);
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },
  async getSectorsList(params = {}) {
    const response = await requestService.get(`${sectorPrefix}/list/details`, params);
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },
  async getCellsList(params = {}) {
    const response = await requestService.get(`${cellsPrefix}/list/details`, params);
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },
  async getMoveListItemsDetails(params = {}) {
    const response = await requestService.get(`${cellsPrefix}/items/move/list/details`, params);
    return response?.data?.object;
  },
  async deleteMoveListItemDetail(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${cellsPrefix}/items/move/list/details/delete/${uuid}`);
  },
  async clearMoveList() {
    // eslint-disable-next-line no-return-await
    return await requestService.get(`${cellsPrefix}/items/move/list/clear`);
  },


  async getAllStates(params = {}) {
    const response = await requestService.get("/states/list", params);
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },
  async getAllItems(params = {}) {
    const response = await requestService.get(`${cellsPrefix}/items/list/details`, params);
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },
  async getAllSearchItems(params = {}) {
    const response = await requestService.get(`${cellsPrefix}/items/list/ns`, params);
    return response?.data?.object;
  },

  async addMoveListItem(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${cellsPrefix}/items/move/list/add`, payload,{},false);
  },
  async deleteMoveListItem(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${cellsPrefix}/items/move/list/delete/${uuid}`);
  },
  async addProduct(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${cellsPrefix}/items/add`, payload);
  },
  async getProductsDescriptions(params) {
    const response = await requestService.get("/products/list",params);
    return response?.data?.object;
  },
  async addProductDescription(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post("/products/add", payload);
  },
  async deleteProductDescription(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`/products/delete/${uuid}`);
  },
  async updateProductDescription(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post("/products/update", payload,{},false);
  },
  async addProductOptions(payload) {
    // eslint-disable-next-line no-return-await
    const response = await requestService.post("/products/options/add", payload);
    return response?.data?.object
  },
  async updateMoveListItem(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${cellsPrefix}/items/move/list/state/set`, payload);
  },
  // CLIENT
  async getMyProducts(params = {}) {
    const response = await requestService.get(`${cellsPrefix}/items/list/details`, params);
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },

  async getItemsChipList(params) {
    if(params.uuid_user){
      const response = await requestService.get(`${cellsPrefix}/items/list/chips?uuid_user=${params.uuid_user}`);
      return response?.data?.object;
    }else{
      const response = await requestService.get(`${cellsPrefix}/items/list/chips`);
      return response?.data?.object;
    }
  },
  async getItemsChipListCatalog(params) {
    const response = await requestService.get("/products/list/chips",params);
    return response?.data?.object;
  },

  async getMyСonfirmedProducts(payload,details) {
    if(details){
      const response = await requestService.get(`/deliveries/confirmed/items/list/${payload}?details=${details}`);
      return response?.data?.object;
    }else{
      const response = await requestService.get(`/deliveries/confirmed/items/list/${payload}`);
      console.log('getMyСonfirmedProducts', response)
      return response?.data?.object;
    }
  },
  // CLIENT
  async getProduct(params = {}) {
    const response = await requestService.get(`/products/info/${params}`);
    // console.log('response' ,response)
    return response?.data?.object;
  },

  async getHistory(params = {}) {
    const response = await requestService.get(`${prefix}/sectors/cells/items/history/${params}`);
    return response?.data?.object;
  },
  async getItemInfo(params = {}) {
    const response = await requestService.get(`${prefix}/sectors/cells/items/info/${params}`);
    return response?.data?.object;
  }
};
