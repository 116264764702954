import auth from "./auth.json";
import error from "./error.json";
import menu from "./menu.json";
import orderDialog from "./orderDialog.json";
import paymentPlan from "./payment-plan.json";
import moveList from "./move-list.json";
import cell from "./cell.json";
import cabinet from "./cabinet.json";
import crm from "./crm.json";
import confirmDialog from "./confirm-dialog.json";
import addresses from "./adresses.json";
import table from "./table.json";
import users from "./users.json";
import products from "./products.json";
import supply from "./supply.json";
import supplyStatuses from "./supply-statuses.json";
import profile from "./profile.json";
import catalog from "./catalog.json";
import sectors from "./sectors.json";
import storages from "./storages.json";
import nestedData from "./nested-data.json";
import chat from "./chat.json";
import history from "./history.json";
import chart from "./chart.json";
import printDialog from './print-dialog.json'
import market from './market.json'
import landing from './landing.json'
import documentation from './documentation.json'
import payType from './pay-type.json'

function notEngTxt(constant) {
  return `Поле «${constant}»  должно быть кириллицей!`;
}

function isRequired(constant) {
  return `Поле «${constant}»  обязательное для заполнения!`;
}

export default {
  auth: { ...auth },
  error: { ...error },
  menu: { ...menu },
  orderDialog: { ...orderDialog },
  paymentPlan: { ...paymentPlan },
  moveList: { ...moveList },
  cell: { ...cell },
  cabinet: { ...cabinet },
  crm: { ...crm },
  table: { ...table },
  confirmDialog: { ...confirmDialog },
  addresses: { ...addresses },
  users: { ...users },
  products: { ...products },
  supply: { ...supply },
  supplyStatuses: { ...supplyStatuses },
  profile: { ...profile },
  catalog: { ...catalog },
  sectors: { ...sectors },
  storages: { ...storages },
  nestedData: { ...nestedData },
  chat: { ...chat },
  history: { ...history },
  chart: { ...chart },
  printDialog: { ...printDialog },
  market: { ...market },
  landing: { ...landing },
  documentation: { ...documentation },
  payType: {...payType},
  mainTitle: "Ваш Онлайн Склад",
  loadMore: "Загрузить больше",
  translations:"ПЕРЕВОДЫ",
  form: {
    discount: "Акция",
    rating: "Рейтинг",
    email: "E-mail",
    password: "Пароль",
    name: "Имя",
    surname: "Фамилия",
    phone_number: "Номер телефона",
    city: "Город",
    status: "Статус",
    time_created: "Дата создания",
    description: "Описание",
    title: "Название",
    storage: "Склад",
    company: "Компания",
    crm_user: "CRM пользователь",
    end_crm_subs: "Завершение подписки CRM",
    more: "Подробнее",
    addComment: "Добавить комментарий",
    department: "Отделение",
    add: "Добавить",
    crm_end_date: "Завершение подписки CRM",
    edit: "Редактировать",
    payment_plan: "Тарифный план",
    not_selected: "Не выбрано",
    photo: "Фото",
    search: "Поиск",
    client: "Клиент",
    comment: "Комментарий",
    ttn: "ТТН",
    counterparty: "Контрагент",
    sender_name: "Имя отправителя",
    sender_surname: "Фамилия отправителя",
    phone_sender: "Номер отправителя",
    phone_receiver: "Номер получателя",
    name_receiver: "Имя получателя",
    surname_receiver: "Фамилия получателя",
    city_receiver: "Город получателя",
    payment_made: "Наложенный платеж",
    price: "Стоимость",
    weight: "Вес",
    shipping_cost: "Стоимость отправки",
    choose: "Выберите",
    sector: "Сектор",
    cell: "Ячейка",
    top_sell : "Хит продаж",
    counterparty_sender:"Отправитель контрагента",
    date:"Дата",
    msg:"Сообщение",
    not_specified: "Не указано",
    forms:"Формы",
    control: "Управление",
    errors: {
      EmailRequired: isRequired("E-mail"),
      PasswordRequired: isRequired("Пароль"),
      NameRequired: isRequired("Имя"),
      SurnameRequired: isRequired("Фамилия"),
      PhoneNumberRequired: isRequired("Номер телефона"),
      TitleRequierd: isRequired("Название"),
      RegionRequired: isRequired("Регион"),
      CityRequired: isRequired("Город"),
      DepartmentRequired: isRequired("Отделение"),
      StreetRequired:isRequired("Улица"),
      HouseRequired:isRequired("Дом"),
      StorageRequired: isRequired("Склад"),
      ItemRequired: isRequired("Item"),
      WhereRequired: isRequired("Куда"),
      StatusRequired: isRequired("Статус"),
      AmountRequired: isRequired("Количество"),
      CurrentPassRequired:isRequired('Текущий пароль'),
      NewPassRequired:isRequired('Новый пароль'),
      ConfirmPassRequired:isRequired('Подтвердите пароль'),
      CounterpartySenderRequired:isRequired('Отправитель контрагента'),
      CounterpartyRequired:isRequired('Контрагент'),
      SectorRequired:isRequired('Сектор'),
      NsCodeRequired:isRequired('Ns код'),
      AddressesRequired:isRequired('Адрес'),
      DescRequired:isRequired('Описание'),
      PriceRequired:isRequired('Цена'),
      paymentPlanHoursPriceRequired:isRequired('Цена за h/m³'),
      paymentPlansDeliveryPriceRequired:isRequired('Цена за отправку'),
      ReasonDescRequired:isRequired('Описание причины'),

      ChoseDeport: "Выберите склад",
      EnterTTN: "Введите ТТН",
      NonExistentTtn: "Введен несуществующий ТТН",
      EmailMustBeValid: "E-mail должен быть валидным!",
      PasswordMustBeLike:
        "Пароль должен содержать прописные и строчные буквы латиницы, цифры и символы, минимум 8 символов!",
      PhoneNumberMustBeLike: "Номер телефона должен быть в виде +38 (999) 99-99-999",
      AtLeastOne: "должно быть минимум 1!",
      PasswordsDontMatch:"Пароли не совпадают",
      NsCodeValidation:"NS код должен начинаться с 88 и состоять из 16 цифр!"
    },
    hint: {
      NameNoEngText: notEngTxt("Имя"),
      SurnameNoEngText: notEngTxt("Фамилия"),
      CommentNoEngText: notEngTxt("Комментарий")
    }
  },
  btn: {
    create: "Создать",
    cancel: "Отменить",
    update: "Обновить",
    back: "Обратно",
    addOrder: "Создать заказ",
    all: "Все",
    delete: "Удалить",
    close: "Закрыть",
    print: "Печатать",
    confirm: "Подтвердить",
    generate: "Сгенерировать",
    send: "Отправить",
    clear: "Очистить",
    create_apply: "Создать запрос",
    log_out: "Выйти",
    add: "Добавить",
    save: "Сохранить",
    increaseDimenstion:"Увеличить размер",
    decreaseDimenstion:"Уменьшить размер",
    order:'Заказать'
  },
  offline: {
    title: 'Нет подключения к Интернету',
    subTitle: 'Проверьте подключение к Интернету.'
  }
};
