import { mapActions, mapGetters } from 'vuex';
import * as getterTypes from '@/store/modules/novaPochta/types/getters';
import * as actionTypes from '@/store/modules/novaPochta/types/actions';
import notifications from './notifications';

export default {
  mixins: [notifications],
  data: () => ({
    activeCities: [],
    regionLoading: false,
  }),
  methods: {
    ...mapActions('novaPochta', {
      fetchNovaPochtaCities: actionTypes.FETCH_CITIES,
      fetchWarehouses: actionTypes.FETCH_WAREHOUSES,
      fetchWarehouseByRef: actionTypes.FETCH_WAREHOUSE_BY_REF,
      fetchNPCitiesByRegion: actionTypes.FETCH_CITIES_BY_REGION,
    }),
    clearWarehouse() {
      if (this.delivery) {
        this.delivery.warehouse = '';
      }
      if (this.address) {
        this.address.warehouse = '';
      }
    },
    updateNameAddress() {
      if (this.delivery.name_city) {
        // console.log('this.warehouses', this.warehouses);
        const city = this.cities.find((cityItem) => cityItem.Ref === this.inputDelivery.city);
        this.delivery.name_city = city?.Description || this.item.delivery_address_details.name_city;
        const warehouse = this.warehouses.find((cityItem) => cityItem.Ref === this.inputDelivery.warehouse);
        // this.inputDelivery.warehouse_id = warehouse?.Number || 0;
        this.inputDelivery.name_warehouse = warehouse?.Description || '';
      }
    },
    async onSelectCity(cityRef,info) {
      try {
        console.log(info)
        if(info == 'delivery'){
          const item = this.cities.find((cityItem) => cityItem.Ref === this.inputDelivery.city);
          this.inputDelivery.name_city = item?.Description || '';
          // console.log(this.delivery.name_city);
        }
        if(info == 'createAdress'){
          const item = this.cities.find((cityItem) => cityItem.Ref === this.address.city);
          console.log(this.address)
          this.address.name_city = item.Description;
          console.log(this.address.name_city)
        }
        if(info == 'editAdress'){
          const item = this.cities.find((cityItem) => cityItem.Ref === this.addressItem.uuid_ref_city);
          console.log(this.address)
          this.addressItem.name_city = item.Description;
        }
        await this.fetchWarehouses(cityRef);
        this.clearWarehouse();
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async onSelectWarehouse(info) {
      try {
      if(info == 'delivery'){
        const item = this.warehouses.find((cityItem) => cityItem.Ref === this.inputDelivery.warehouse);
        this.inputDelivery.name_warehouse = item.Description;
        // this.delivery.warehouse_id = item?.Number || 0;
      }
      if(info == 'createAdress'){
        const item = this.warehouses.find((cityItem) => cityItem.Ref === this.address.warehouse);
        this.address.name_warehouse = item.Description;

      }
      if(info == 'editAdress'){
      const item = this.warehouses.find((cityItem) => cityItem.Ref === this.addressItem.uuid_ref_warehouse);
      this.addressItem.name_warehouse = item.Description;
      }
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async onSelectRegion(regionRef) {
      try {
        this.regionLoading = true;
        await this.fetchNPCitiesByRegion(regionRef);
        // this.clearWarehouse();
        this.activeCities = this.cities.filter((city) => city.AreaRef === regionRef);
        this.regionLoading = false;
      } catch (e) {
        this.regionLoading = false;
        this.setErrorNotification(e);
      }
    },
  },
  computed: {
    ...mapGetters('novaPochta', {
      cities: getterTypes.GET_CITIES,
      warehouses: getterTypes.GET_WAREHOUSES,
    }),
  },
};
