import * as mutationTypes from './types/mutations';
import ru from './locales/ru';
import ua from './locales/ua';

export default {
  [mutationTypes.SET_CURRENT_LANGUAGE]: (state, language) => {
    state.language = language;
  },
  [mutationTypes.SET_LANGUAGE_CONSTANTS]: (state, language) => {
    if (language === 'ru') {
      state.languageConstants = ru;
    }
    if (language === 'ua') {
      state.languageConstants = ua;
    }
  },
};
