import * as mutationTypes from './types/mutations';

export default {
  [mutationTypes.SET_NOTIFICATION]: (state, data) => {
    state.notifications = {
      ...state.notifications,
      [data.id]: data,
    };
  },
  [mutationTypes.CLOSE_NOTIFICATION]: (state, id) => {
    const notifications = { ...state.notifications };
    delete notifications[id];
    state.notifications = notifications;
  },
};
