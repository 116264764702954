// eslint-disable-next-line import/prefer-default-export
export const userNormalizer = (User) => ({
  name: User?.name,
  surname: User?.surname,
  uuid_crm_apikey: User?.uuid_crm_apikey,
  is_np_apikey: User?.is_np_apikey != '0',
  id_state: User?.id_state,
  np_apikey: User?.np_apikey,
  phone: User?.phone,
  email: User?.email,
  company: User?.company,
  image: User?.path_img,
  uuid: User?.uuid,
  type: User?.type,
  crm_is_active: User?.crm_is_active,
  uuid_user_plan: User?.uuid_user_plan,
  turbosms_sender:User?.turbosms_sender,
  turbosms_apikey:User?.turbosms_apikey,
  is_turbosms_active:User?.is_turbosms_active != '0',
  crm_days_left:User.crm_days_left,
  date_crm_end:User.date_crm_end,
  uuid_deport:User.uuid_deport
});
