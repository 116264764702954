import * as mutations from './types/mutations';

export default {
  [mutations.SET_CELLS]: (state, cells) => {
    state.cells = cells
  },
  [mutations.SET_PRODUCTS]: (state, products) => {
    state.products = products
  },
  [mutations.SET_SECTORS]: (state, sectors) => {
    state.sectors = sectors
  },
  [mutations.SET_DEPORTS]: (state, deports) => {
    state.deports = deports
  },
};
