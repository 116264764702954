import requestService from '../requestService';

const prefix = '/addresses';

export default {
  async getAddressesList(params = {}) {
    const response = await requestService.get(`${prefix}/list`, params);
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },
  async createAddress(payload) {
    const response = await requestService.post(`${prefix}/add`, payload);
    return response?.data?.object;
  },
  async updateAddress(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/update`, payload);
  },
  async deleteAddress(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/delete/${uuid}`);
  },
};
