<template>
  <v-fade-transition >
    <!-- <div class="main-wrapper" v-if="isLoading">
      <div class="line-wrapper">
        <v-progress-linear
          indeterminate
          :height="1"
          color="#4FAE3B"
          class="loader-line"
        ></v-progress-linear>
      </div>
    </div> -->
  </v-fade-transition>
</template>

<script>
import { mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/loader/types/getters";

export default {
  name: "Loader",
  computed: {
    ...mapGetters("loader", {
      isLoading: getterTypes.IS_LOADING
    })
  }
};
</script>

<style>
.main-wrapper{
  width: 100%;
  height: 56px;
  left: 0;
  top: 0;
  z-index: 200;
  position: absolute;
  cursor:wait;
}
.line-wrapper {
  position: absolute;
  top: 56px;
  left: 0;
  height: auto;
  width: 100%;
  z-index: 200;
}
.loader-line {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
