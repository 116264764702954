<template>
  <VMenu offset-y content-class="language">
    <template v-slot:activator="{ on, attrs }">
      <VBtn
        text
        :icon="$vuetify.breakpoint.mobile"
        v-bind="attrs"
        v-on="on"
        class="px-md-2 mx-1"
        :outlined="isOutlined"
        :small="$vuetify.breakpoint.mobile"
      >
        <VIcon color="grey darken-1" :dense="$vuetify.breakpoint.mobile">mdi-translate</VIcon>
        <VIcon color="grey" v-if="!$vuetify.breakpoint.mobile">mdi-menu-down</VIcon>
      </VBtn>
    </template>
    <VCard>
      <VList class="language__list pa-2" dense nav>
        <VListItemTitle
          style="font-size: 12px; font-weight:700"
          class="mb-2 mt-3 mx-2 text-center"
          >{{ $t("translations") }}</VListItemTitle
        >
        <VListItem
          class="language__list-item "
          v-for="(item, index) in languages"
          :key="index"
          @click="switchLanguage(item)"
          :class="{ active: currentLanguage.translationKey === item.translationKey }"
        >
          <span class="flag__wrapper">
            <country-flag v-if="item.flagCode" :country="item.flagCode" size="small" />
            <span v-else class="flag__wrapper">
              <img  class="no-flag" src="https://flagicons.lipis.dev/flags/4x3/xx.svg" />
            </span>
          </span>
          <span>
            {{ item.title }}
          </span>
        </VListItem>
      </VList>
    </VCard>
  </VMenu>
</template>

<script>
import { mapGetters } from "vuex";
import CountryFlag from "vue-country-flag";

export default {
  components: { CountryFlag },
  name: "Language",
  props: {
    isOutlined: {
      require: false,
      default: true
    }
  },
  methods: {
    getFlagEmoji(countryCode) {
      if (!countryCode) return "🏳️";
      const res = countryCode
        .toUpperCase()
        .replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt()));
      return res;
    },
    switchLanguage(item) {
      if (this.currentLanguage.translationKey !== item.translationKey) {
        this.$setLanguage(item.translationKey);
      }
    }
  },
  computed: {
    ...mapGetters(["languages", "currentLanguage"])
  }
};
</script>
<style lang="scss">
.main {
  .language__btn {
    position: absolute;
    top: 20px;
    right: 20px;
    margin: 0;
    svg {
      width: 32px;
      height: auto;
      display: block;
      path {
        stroke: #3d3d3d;
      }
    }
  }
}
.language {
  &__btn {
    display: block;
    align-items: center;
    justify-content: center;
    margin: 0 12px;
    svg {
      width: 32px;
      height: auto;
      display: block;
    }
  }
  &__list {
    min-width: 100px;
    font-size: 14px;
    &-item {
      display: flex;
      align-items: center;
      min-height: 28px !important;
      cursor: pointer !important;
      transition: all 0.3s ease;
      &.v-list-item.theme--light.active {
        color: rgba(79, 174, 59, 1) !important;
        font-weight: 500;
        background-color: rgba(79, 174, 59, 0.12);
        &:hover {
          background-color: rgba(78, 174, 59, 0.214);
        }
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }
}
.flag__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-flag{
  margin: -0.9em -1.2em -.9em -1.2em;
  transform: scale(.25);
  width: 52px;
    height: 39px;
    border: thin solid #c2c2c2;
}
</style>
