export const CityNormalizer = (City) => ({
  CityID: City.CityID,
  Description: City.Description,
  AreaRef: City.Area,
  AreaDescriptionRu: City.AreaDescriptionRu,
  AreaDescription: City.AreaDescription,
  DescriptionRu: City.DescriptionRu,
  Ref: City.Ref,
});

export const WarehouseNormalizer = (Warehouse) => ({
  ...Warehouse,
});
