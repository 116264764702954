// eslint-disable-next-line import/no-cycle
/* eslint-disable import/no-cycle */
import joinRequestService from '@/services/request/joinRequest/joinRequestService';
import * as actions from './types/actions';
import * as mutations from './types/mutations';

export default {
  [actions.FETCH_AMOUNT]: async ({ commit,state }) => {
      const amount =  await joinRequestService.getAmountNew();
      commit(mutations.SET_AMOUNT, amount.split(' ')[2]);
  },
};
