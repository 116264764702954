import * as mutationTypes from './types/mutations';

export default {
  [mutationTypes.SET_UUID_BOARD]: (state, uuid) => {
    state.uuid_board = uuid;
    localStorage.setItem('crm_uuid_board', uuid);
  },
  [mutationTypes.SET_UUID_TAB]: (state, uuid) => {
    state.uuid_tab = uuid;
    localStorage.setItem('crm_uuid_tab', uuid);
  },
};
