import auth from "./auth.json";
import error from "./error.json";
import menu from "./menu.json";
import orderDialog from "./orderDialog.json";
import paymentPlan from "./payment-plan.json";
import moveList from "./move-list.json";
import cell from "./cell.json";
import cabinet from "./cabinet.json";
import crm from "./crm.json";
import confirmDialog from "./confirm-dialog.json";
import addresses from "./addresses.json";
import table from "./table.json";
import users from "./users.json";
import supply from "./supply.json";
import supplyStatuses from "./supply-statuses.json";
import profile from "./profile.json";
import catalog from "./catalog.json";
import sectors from "./sectors.json";
import storages from "./storages.json";
import nestedData from "./nested-data.json";
import products from "./products.json";
import chat from "./chat.json";
import history from "./history.json";
import chart from "./chart.json";
import printDialog from "./print-dialog.json";
import market from "./market.json";
import callCenter from "./call-center.json";
import sms from "./sms.json";
import landing from "./landing.json";
import documentation from './documentation.json'
import payType from './pay-type.json'

function notEngTxt(constant) {
  return `Поле «${constant}»  повинне бути кирилицею!`;
}
function isRequired(constant) {
  return `Поле «${constant}»  обов'язкове для заповнення!`;
}


export default {
  auth: { ...auth },
  error: { ...error },
  menu: { ...menu },
  cell: { ...cell },
  crm: { ...crm },
  cabinet: { ...cabinet },
  orderDialog: { ...orderDialog },
  paymentPlan: { ...paymentPlan },
  moveList: { ...moveList },
  addresses: { ...addresses },
  table: { ...table },
  users: { ...users },
  confirmDialog: { ...confirmDialog },
  supply: { ...supply },
  supplyStatuses: { ...supplyStatuses },
  sectors: { ...sectors },
  profile: { ...profile },
  catalog: { ...catalog },
  storages: { ...storages },
  nestedData: { ...nestedData },
  products: { ...products },
  chat: { ...chat },
  history: { ...history },
  chart: { ...chart },
  printDialog: { ...printDialog },
  market: { ...market },
  callCenter: { ...callCenter },
  sms: { ...sms },
  landing: { ...landing },
  documentation: { ...documentation },
  payType: { ...payType },
  mainTitle: "Ваш Онлайн Склад",
  loadMore: "Завантажити більше",
  translations:"ПЕРЕКЛАДИ",
  form: {
    discount: "Акція",
    rating: "Рейтинг",
    email: "E-mail",
    password: "Пароль",
    name: "Ім'я",
    surname: "Прізвище",
    phone_number: "Номер телефону",
    city: "Місто",
    status: "Статус",
    time_created: "Дата створення",
    description: "Опис",
    title: "Назва",
    storage: "Склад",
    company: "Компанія",
    crm_user: "CRM користувач",
    end_crm_subs: "Завершення підписки CRM",
    more: "Детальніше",
    addComment:"Додайте коментар",
    department:"Відділення",
    add:'Додати',
    edit:"Редагувати",
    crm_end_date:"Завершення підписки CRM",
    payment_plan:"Тарифний план",
    not_selected:"Не вибрано",
    photo:'Фото',
    search:"Пошук",
    client:"Клієнт",
    ttn:"ТТН",
    comment:"Коментар",
    counterparty:"Контрагент",
    sender_name:"Ім'я відправника",
    sender_surname:"Прізвище відправника",
    phone_sender:"Номер відправника",
    phone_receiver:"Номер отримувача",
    name_receiver:"Ім`я отримувача",
    surname_receiver:"Прізвище отримувача",
    city_receiver:"Місто отримувача",
    payment_made:"Накладений платіж",
    price:"Вартість",
    weight:"Вага",
    shipping_cost:"Вартість відправки",
    choose:"Оберіть",
    sector:"Сектор",
    cell:"Комірка",
    top_sell : "Хіт продажу",
    date:"Дата",
    counterparty_sender:"Відправник контрагента",
    msg:"Повідомлення",
    not_specified: "Не вказано",
    forms:"Форми",
    control: "Керування",
    errors: {
      EmailRequired: isRequired("E-mail"),
      PasswordRequired: isRequired("Пароль"),
      NameRequired: isRequired("Ім\'я"),
      SurnameRequired:isRequired("Прізвище"),
      PhoneNumberRequired: isRequired("Номер телефону"),
      TitleRequierd: isRequired("Назва"),
      RegionRequired:isRequired("Регіон"),
      CityRequired:isRequired("Місто"),
      StreetRequired:isRequired("Вулиця"),
      HouseRequired:isRequired("Будинок"),
      DepartmentRequired:isRequired("Відділення"),
      StorageRequired:isRequired('Склад'),
      ItemRequired:isRequired('Item'),
      WhereRequired:isRequired('Куди'),
      StatusRequired:isRequired('Статус'),
      AmountRequired:isRequired('Кількість'),
      CurrentPassRequired:isRequired('Поточний пароль'),
      NewPassRequired:isRequired('Новий пароль'),
      ConfirmPassRequired:isRequired('Підтвердіть пароль'),
      CounterpartySenderRequired:isRequired('Відправник контрагента'),
      CounterpartyRequired:isRequired('Контрагент'),
      SectorRequired:isRequired('Сектор'),
      NsCodeRequired:isRequired('Ns код'),
      AddressesRequired:isRequired('Адреса'),
      DescRequired:isRequired('Опис'),
      PriceRequired:isRequired('Вартість'),
      paymentPlanHoursPriceRequired:isRequired('Ціна за h/m³'),
      paymentPlansDeliveryPriceRequired:isRequired('Ціна за відправку'),
      ReasonDescRequired:isRequired('Опис причини'),


      ChoseDeport: "Оберіть склад",
      EmailMustBeValid: "E-mail повинен бути валідним!",
      EnterTTN:"Введіть ТТН",
      NonExistentTtn:'Введено неіснуючий ТТН',
      PasswordMustBeLike:
        "Пароль повинен містити великі та малі літери латиниці, цифри та символи, мінімум 8 символів!",
      PhoneNumberMustBeLike: "Номер телефону повинен бути у вигляді +38 (999) 99-99-999",
      AtLeastOne:"повинна бути мінімум 1!",
      PasswordsDontMatch:"Паролі не збігаються",
      NsCodeValidation:"NS код повинен починатися з 88 та складатися з 16 цифр!"

    },
    hint: {
      NameNoEngText: notEngTxt("Ім'я"),
      SurnameNoEngText: notEngTxt("Прізвище"),
      CommentNoEngText: notEngTxt("Коментар"),
    }
  },
  btn: {
    create: "Створити",
    cancel: "Скасувати",
    update: "Оновити",
    back:"Назад",
    addOrder:"Створити замовлення",
    all:"Всі",
    delete:"Видалити",
    close:"Закрити",
    print:"Друк",
    confirm:"Підтвердити",
    generate:"Згенерувати",
    send:"Відправити",
    clear:"Очистити",
    create_apply:"Створити запит",
    log_out:"Вийти",
    add:"Додати",
    save:"Зберегти",
    increaseDimenstion:"Збільшити розмір",
    decreaseDimenstion:"Зменшити розмір",
    order:'Замовити',
    apply_filters:"Застосувати фільтри"

  },
  offline: {
    title: 'Немає підключення до Інтернету',
    subTitle: 'Перевірте підключення до Інтернету.'
  }
};
