<template>
  <div>
    <v-simple-table v-if="changes.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">
              {{$t('history.changes')}}
            </th>
            <th class="text-center">
               {{$t('history.time')}}
            </th>
            <th class="text-center">
               {{$t('history.initiator')}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, id) in changes" :key="id">
            <td class="text-left">
              <span v-for="(item, index) in item.info" :key="index">
                <VRow no-gutters class="my-1">
                  <VCol cols="4" v-if="index == 'name' ">{{ $t('table.name') || "--" }} </VCol>
                  <VCol cols="4" v-else-if="index == 'bar_code' ">{{ $t('table.bar_code') || "--" }} </VCol>
                  <VCol cols="4" v-else-if="index == 'type' ">{{ $t('table.type') || "--" }} </VCol>
                  <VCol cols="4" v-else-if="index == 'ns_code' ">{{ $t('table.ns_code') || "--" }} </VCol>
                  <VCol cols="1">–</VCol>
                  <VCol> {{ item || "--" }} <br /></VCol>
                </VRow>
              </span>
            </td>
            <td class="text-center">{{ item.time_updated || "--" }}</td>
            <td class="text-center">{{ item.user_name || "--" }} {{ item.user_surname || "--" }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import depotService from "../../../services/request/depot/depotService";
import langMixin from '@/mixins/language';

export default {
  mixins:[langMixin],
  data: () => ({
    changes: [],
    tmpChanges: []
  }),
  props: {
    historyInfo: {
      require: true
    }
  },
  watch: {
    historyInfo: {
      deep: true,
      handler(e) {
        this.getInfo();
      }
    }
  },
  methods: {
    getInfo() {
      this.historyInfo.forEach(element => {
        if (element.changes.info_uuid_cell) {
          this.getChangeInfo(element.changes.info_uuid_cell, element, this.$t('history.cell'));
        }
        if (element.changes.info_uuid_deport) {
          this.getChangeInfo(element.changes.info_uuid_deport, element, this.$t('history.storage'));
        }
        if (element.changes.info_uuid_product) {
          this.getChangeInfo(element.changes.info_uuid_product, element, this.$t('history.item'));
        }
        if (Object.keys(this.tmpChanges).length > 0) {
          this.changes.push(this.tmpChanges);
        }
        this.tmpChanges = [];
      });
      // orderDialog(this.changes);
    },
    getChangeInfo(changeInfo, mainInfo, typeText) {
      // orderDialog(changeInfo);
      const params = [];
      params.info = changeInfo;
      params.info.type = typeText;
      this.tmpChanges = params;
      this.tmpChanges.user_name = mainInfo.user_name;
      this.tmpChanges.user_surname = mainInfo.user_surname;
      this.tmpChanges.time_updated = mainInfo.time_updated;
    },
    getChanges() {
      this.changes.forEach(element => {});
    }
  }
};
</script>

<style lang="scss" scoped></style>
