import { mapActions } from 'vuex';
import * as actionTypes from '@/store/modules/notifications/types/actions';

export default {
  methods: {
    ...mapActions('notifications', {
      setInfoNotification: actionTypes.SET_INFO_NOTIFICATION,
      setSuccessNotification: actionTypes.SET_SUCCESS_NOTIFICATION,
      setErrorNotification: actionTypes.SET_ERROR_NOTIFICATION,
    }),
  },
};
