// export default {
    // data:()=>({
    //     LargeSize:false,
    // }),

    // computed:{
    //     changeUI(e){
    //         if(e == 'small'){
    //          localStorage.removeItem('sizeUI');
    //          localStorage.setItem('sizeUI', 'small');
    //          this.LargeSize = false
    //         }else{
    //             localStorage.removeItem('sizeUI');
    //             localStorage.setItem('sizeUI', 'large');
    //              this.LargeSize = true
    //         }
    //     },
    // },
    // mounted(){
    //     const size = localStorage.getItem('sizeUI',this);
    //     if(size == 'small'){
    //         this.LargeSize = false
    //     }else{
    //         this.LargeSize = true
    //     }
    // }

// }
import { mapActions, mapGetters } from 'vuex';
import * as actionTypes from '@/store/modules/sizeUI/types/actions';
import * as getterTypes from '@/store/modules/sizeUI/types/getters';

export default {
  methods: {
    ...mapActions('sizeUI', {
      setSize: actionTypes.SET_SIZE,
    }),
  },
  computed: {
    ...mapGetters('sizeUI', {
      isLarge: getterTypes.IS_LARGE,
    }),
  },
};
