<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    xmlns="http://www.w3.org/2000/svg"
    :fill="fill"
  >
    <path d="M0 13.8888L3.19342 10.6786C3.25106 10.6209 3.34329 10.6613 3.34329
    10.7421V12.6994C3.34329 12.7456 3.38364 12.7861
     3.42975 12.7861H5.67783C5.96028 12.7861 6.19085 13.017 6.19085 13.2999V14.6279C6.19085 14.9108
     5.96028 15.1418 5.67783 15.1418H3.44128C3.39517 15.1418 3.35482 15.1822 3.35482
     15.2284V17.1857C3.35482 17.2607 3.26259 17.3012 3.21071 17.2492C3.1646 17.2088
     3.13001 17.1741 3.09542 17.1395C2.11549 16.158 1.14133 15.1764 0.1614 14.2006C0.12105
      14.1602 0.0691715 14.1198 0.0172929 14.0852C0.0115286 14.0043 0.00576429 13.9697 0
      13.8888Z"/>
    <path
      d="M13.9439 28C12.8948 26.9549 11.8399 25.9041 10.7389 24.8013C10.6813 24.7436
       10.7216 24.6512 10.8023 24.6512H12.7507C12.7968 24.6512 12.8371 24.6108 12.8371
        24.5646V22.3128C12.8371 22.0299 13.0677 21.799 13.3501 21.799H14.6759C14.9584
         21.799 15.1889 22.0299 15.1889 22.3128V24.5473C15.1889 24.5935 15.2293 24.6339
          15.2754 24.6339H17.2295C17.3044 24.6339 17.3448 24.7263 17.2929 24.7782C17.2526
           24.8244 17.218 24.8591 17.1834 24.8937C16.2035 25.8752 15.2235 26.851 14.2494
            27.8326C14.209 27.873 14.1687 27.9249 14.1341 27.9769C14.0591 27.9885 14.0246
             27.9942 13.9439 28Z"/>
    <path
      d="M14.4972 16.8681C14.4972 15.9328 14.5087 14.9974
       14.4914 14.0563C14.4857 13.7561 14.6009 13.5886 14.8776
        13.4731C16.4455 12.8034 18.0019 12.1221 19.564 11.4465C20.0367
         11.2445 20.2672 11.3888 20.2672 11.8969C20.273 13.7791 20.273
         15.6614 20.2845 17.5437C20.2845 17.8208 20.1865 17.9882 19.9271
         18.0979C18.3362 18.7793 16.751 19.4721 15.1658 20.1592C14.722 20.3497
          14.5087 20.1996 14.5087 19.7088C14.5087 18.7619 14.5087 17.815 14.5087
          16.8739C14.5029 16.8681 14.5029 16.8681 14.4972 16.8681Z"/>
    <path d="M13.6383 16.8855C13.6383 17.8324 13.6383 18.7735 13.6383
    19.7204C13.6383 20.1881 13.4135 20.3382 12.9869 20.1592C11.396
    19.4837 9.81081 18.7966 8.2141 18.1211C7.96047 18.0114 7.85095
    17.8497 7.85672 17.5726C7.86248 15.6845 7.85672 13.7907 7.85095
    11.9027C7.85095 11.4177 8.07 11.2676 8.51385 11.4581C10.099 12.1337
     11.6784 12.815 13.2579 13.4847C13.523 13.5944 13.6325 13.7619
     13.6325 14.0506C13.621 14.9975 13.6268 15.9386 13.6268 16.8855C13.6325
     16.8855 13.6325 16.8855 13.6383 16.8855Z" />
    <path d="M13.99 12.8611C13.8862 12.8207 13.7767 12.7918 13.6787
    12.7514C12.0128 12.0181 10.347 11.2791 8.67533 10.5458C8.49087
     10.465 8.32947 10.3726 8.32947 10.1359C8.32947 9.91069 8.4851
     9.82409 8.65803 9.74325C10.3412 8.99266 12.0186 8.23629 13.7018
      7.49725C13.8574 7.42796 14.088 7.42796 14.2436 7.49725C15.9326
      8.22474 17.6157 8.97534 19.2989 9.72016C19.4776 9.79522 19.6275
       9.8876 19.6275 10.1128C19.6332 10.3322 19.4891 10.4303 19.3104
        10.5112C17.6273 11.2618 15.9499 12.0124 14.2667 12.763C14.186
        12.7976 14.088 12.8265 13.99 12.8611Z" />
    <path
      d="M17.1257 3.2824H15.2811C15.235 3.2824
      15.1947 3.32282 15.1947 3.36901V5.61502C15.1947
      5.89793 14.9641 6.12888 14.6816 6.12888H13.3559C13.0734
      6.12888 12.8428 5.89793 12.8428 5.61502V3.37478C12.8428 3.32859
      12.8025 3.28818 12.7564 3.28818H10.9176C10.8369 3.28818 10.8023
       3.1958 10.8542 3.13806C11.8917 2.09877 12.9351 1.04794 13.9611
       0.0259821C13.9957 -0.00866069 14.0476 -0.00866069 14.0822
        0.0259821C15.0851 1.03062 16.1285 2.07568 17.1833 3.13228C17.241
         3.18425 17.2064 3.2824 17.1257 3.2824Z" />
    <path
      d="M27.9741 14.0275C26.9481 15.0552
       25.9047 16.1003 24.8441
       17.1569C24.7865 17.2146 24.6942 17.1742 24.6942
       17.0933V15.2284C24.6942 15.1822 24.6539 15.1418
       24.6078 15.1418H22.3597C22.0773 15.1418 21.8467
        14.9109 21.8467 14.6279V13.3C21.8467 13.017 22.0773
        12.7861 22.3597 12.7861H24.5962C24.6424 12.7861 24.6827
        12.7457 24.6827 12.6995V10.8172C24.6827 10.7364 24.7749
         10.7018 24.8326 10.7537C25.8874 11.8103 26.9308 12.8554
         27.9741 13.9004C28.0087 13.9351 28.0087 13.9928 27.9741
         14.0275Z" />
  </svg>
</template>

<script>
import iconMixin from './iconMixin';

export default {
  mixins: [iconMixin],
  name: 'PackageAll',
};
</script>

<style scoped>

</style>
