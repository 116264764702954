import { mapGetters } from 'vuex';
import * as languageTypes from '../store/modules/language/types/getters';

export default {
  computed: {
    ...mapGetters('language', {
      language: languageTypes.GET_CONSTANTS,
    }),
  },
};
