<template>
  <AppLayout>
    <RouterView />
  </AppLayout>
</template>

<script>
// eslint-disable-next-line import/no-cycle
// import store from '@/store';
import AppLayout from "../layout/AppLayout.vue";

export default {
  name: 'LoginGuard',
  // beforeRouteEnter(to, from, next) {
  //   if (store.state.auth.user) {
  //     next();
  //   } else {
  //     next({ path: '/' });
  //   }
  // },
  components: {
    AppLayout
  }
};
</script>

<style scoped>

</style>
