<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    xmlns="http://www.w3.org/2000/svg"
    :fill="fill"
  >
    <path
      d="M0 14V13.4673C0 13.2899 0.0199687 13.1126
      0.0828873 12.9468C0.636016 11.4888 2.82812 9 8
      9C13.1719 9 15.364 11.4888 15.9171 12.9468C15.98
      13.1126 16 13.2899 16 13.4672V14C16 15.1046 15.1046
       16 14 16H2C0.895431 16 0 15.1046 0 14Z"/>
    <circle cx="8" cy="4" r="4"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin';

export default {
  mixins: [iconMixin],
  name: 'Account',
};
</script>

<style scoped>

</style>
