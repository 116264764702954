<template>
  <div class="search_wrapper">
    <div>
      <v-scroll-x-reverse-transition>
        <v-autocomplete
          v-if="showField"
          color="black"
          hide-details
          dense
          hide-selected
          :loading="loading"
          :items="Object.values(items)"
          item-text="ns_code"
          item-value="uuid"
          :label="$t('form.search')"
          :hide-no-data="!search"
          v-click-outside="clickOutside"
          :search-input.sync="search"
          @change="searchItem"
          v-model="searchQuery"
          cache-items
          clearable
          :return-object="true"
          @keyup.enter="searchItemKeyUp"
          inputRef={searchQuery}
        >
        <template v-slot:append-item>
    <div v-if="!loadMoreDisabled" v-observer="{nextPage:nextPage}" />
  </template>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
               {{$t('table.noData')}}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-scroll-x-reverse-transition>
    </div>
    <div>
      <VBtn icon @click="showField =! showField">
        <VIcon>mdi-magnify</VIcon>
      </VBtn>
    </div>
  </div>
</template>

<script>
import depotService from "../../services/request/depot/depotService";

export default {
  data: () => ({
    showField: false,
    loading: false,
    searchQuery: null,
    items: [],
    search: null,
    valueLocal:'',
    pagination:{
      page:1,
      perPage:50,
    },
    loadMoreDisabled:true,
    paginationParams:{}

  }),
  watch: {
    search(val) {
      console.log(val)
      this.paginationParams = {
        ns_code:val
      }
      this.getItems(false,this.paginationParams)
     this.valueLocal = val
      val && val !== this.select;
    },
    searchQuery(e){
  
    },
    showField(e) {
        this.intervalFetchData();
      if (!e) {
        clearInterval(this.intervalFetchData);
      }
      if (this.items.length === 0 ) {
        this.getItems();
      } else {
        // console.log(this.items.length);
      }
    }
  },
  mounted() {
    this.intervalFetchData = () => setInterval(async () => {
      if (this.showField) {
        this.pagination.page = 1
        this.paginationParams = {}
        this.getItems();
      }
    }, 60000);
  },

  methods: {
    nextPage(){
      this.pagination.page += 1;
      this.getItems(true,this.paginationParams)
    },
    clickOutside() {
      // orderDialog();
      if (this.showField && !this.search) {
        this.showField = false;
        this.searchQuery = "";
      }
    },
    searchItem() {
        if (this.searchQuery) {
        this.$emit("showDialog", this.searchQuery.uuid);
      }
      },
      searchItemKeyUp(){
        if(this.valueLocal === this.searchQuery.ns_code) {
          this.$emit("showDialog", this.searchQuery.uuid);
        }
    },
    async getItems(nextPage = false,paramsArg = {}) {
      try {
        const params = {
          offset:this.pagination.page * this.pagination.perPage,
          limit:this.pagination.perPage,
          uselike:true,
          ...paramsArg
        }
        this.loading = true;
        const res = await depotService.getAllSearchItems(params);
        if(nextPage){
          this.items.push(...res)
          this.$forceUpdate()
        }else{
          this.items = res
        }
        this.loading = false;
        this.loadMoreDisabled = res.length < this.pagination.perPage;

      } catch (e) {
        // orderDialog(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search_wrapper {
  width: 100%;
  min-width: 15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
