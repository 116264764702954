// eslint-disable-next-line import/no-cycle
import requestService from '../requestService';

const TOKEN_NAME = 'app_access_token';

export default {
  setToken(token) {
    localStorage.setItem(TOKEN_NAME, token);
  },
  getToken() {
    return localStorage.getItem(TOKEN_NAME);
  },
  removeToken() {
    return localStorage.removeItem(TOKEN_NAME);
  },
  async logIn(payload) {
    const response = await requestService.post('/users/login', payload, {}, false);
    this.setToken(response?.data?.object.apiKey);
    return response?.data?.object;
  },
  async register(payload) {
    const response = await requestService.post('/users/signup', payload,{},false);
    return response;
  },
  async passwordReset(payload) {
    const response = await requestService.post('/users/password/reset',payload,{},false);
    return response;
  },
  async deports() {
    const response = await requestService.get('/deports/list');
    return response;
  },
  async logOut() {
    const response = await requestService.get('/users/logout');
    this.removeToken();
    return response;
  },
  async fetchAuthUserData() {
    const response = await requestService.get('/users/info');
    // const response = await requestService.get('/users/session');
    return response?.data?.object;
  },
  async usersActivate(hash) {
    const response = await requestService.get(`/users/activate/${hash}`);
    return response?.data
  },
};
