// eslint-disable-next-line import/no-cycle
import requestService from '../requestService';

const prefix = '/deliveries';

export default {
  async getSupplyList(params = {}) {
    const response = await requestService.get(`${prefix}/list`, {
      ...params,
      order: 'DESC',
      orderby: 'time_created',
    });
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },
  async getSupplyItem(params ) {
    const response = await requestService.get(`${prefix}/info/${params}`);
    return response?.data?.object;
  },
  async getSupplyMetrics(params ) {
    const response = await requestService.get(`${prefix}/metrics/${params}`,{},{},false);
    return response?.data?.object;
  },
  async addSupplyItem(payload) {
    const response = await requestService.post(`${prefix}/add`, payload);
    return response?.data?.object;
  },
  async returnSupply(payload) {
    const response = await requestService.post(`${prefix}/return/add`, payload,{},false);
    return response?.data?.object;
  },
  async returnItemList(payload) {
    const response = await requestService.get(`${prefix}/return/list?uuid=${payload.uuid}&details=${payload.details}`);
    return response?.data?.object;
  },

  async blockSupplyItem(payload) {
    const response = await requestService.patch(`${prefix}/block/${payload}`);
    return response?.data?.object;
  },
  async unblockSupplyItem(payload) {
    const response = await requestService.patch(`${prefix}/unblock/${payload}`);
    return response?.data?.object;
  },
  async deleteSupplyItem(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/delete/${uuid}`);
  },
  async updateSupplyItem(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/update`, payload);
  },
  async addDeliveryItem(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/items/add`, payload);
  },
  async addDeliveryItemBarCode(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/items/add_bar_code`, payload);
  },
  async addDeliveryItemAddProduct(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/items/add_product`, payload);
  },
  async ChangeItemValue(payload) {
    // eslint-disable-next-line no-return-await
    const response = await requestService.post(`${prefix}/items/amount`,payload);
    return response?.data?.object;

  },
  async startDelivery(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/delivery/start/${payload}`);
  },
  async getNextStatusId(statusId) {
    const response = await requestService.get('/states/next', {
      id_state: statusId,
    });
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },
  async getDeliveryItemDetailsByDeliveryUuid(uuid) {
    const response = await requestService.get(`${prefix}/items/list/details`, {
      uuid_delivery: uuid,
      // id_state: 810,
    });
    // console.log('getDeliveryItemDetailsByDeliveryUuid', response);
    return response?.data?.object;
  },

  async getCompletedItemDetailsByDeliveryUuid(uuid,params) {
    const response = await requestService.get(`${prefix}/completed/items/list/${uuid}`,params);
    // console.log('getDeliveryItemDetailsByDeliveryUuid', response);
    return response?.data?.object;
  },
  async deleteDeliveryItem(itemUuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/items/delete/${itemUuid}`, {}, false);
  },
  async deleteDeliverySuplly(supllyUuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/delete/${supllyUuid}`);
  },
  async chatLog(deliveriesOutUuid) {
    // eslint-disable-next-line no-return-await
    const resposne = await requestService.get(`${prefix}/chat/log/${deliveriesOutUuid}`);
    return resposne?.data?.object;

  },
  async chatMessage(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/chat/message`,payload);
  },
};
