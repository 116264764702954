import { mapGetters, mapActions } from 'vuex';
import * as getterTypes from '../store/modules/supplyCart/types/getters';
import * as actionTypes from '../store/modules/supplyCart/types/actions';

export default {
  methods: {
    ...mapActions('supplyCart', {
      openCart: actionTypes.OPEN_CART_DIALOG,
      closeCart: actionTypes.CLOSE_CART_DIALOG,
      addProductCart: actionTypes.SET_SUPPLY_PRODUCT,
      deleteProductCart: actionTypes.REMOVE_SUPPLY_PRODUCT,
      clearCart: actionTypes.CLEAR_CART,
    }),
  },
  computed: {
    ...mapGetters('supplyCart', {
      supplyProducts: getterTypes.GET_SUPPLY_PRODUCTS,
      cartVisibility: getterTypes.GET_VISIBILITY,
    }),
  },
};
