<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.92651 17.5289C6.92651 16.5528 6.92651 15.5768 6.92651
      14.6008C6.92651 14.286 7.05245 14.1286 7.33581 14.0026C8.97299
       13.31 10.5787 12.5858 12.2159 11.8932C12.7196 11.6728 12.94
       11.8302 12.94 12.3654C12.94 14.3175 12.94 16.2695 12.9715
       18.2215C12.9715 18.5049 12.877 18.6938 12.5937 18.7882C10.925
        19.4809 9.28783 20.205 7.65065 20.9292C7.1469 21.1495 6.92651
         20.9921 6.92651 20.4884C6.92651 19.5124 6.92651 18.5363
         6.92651 17.5289Z"
    />
    <path
      d="M6.01349 17.5603C6.01349 18.5363 6.01349
      19.5123 6.01349 20.5198C6.01349 20.9921 5.7931
       21.1495 5.32084 20.9606C3.68366 20.268 2.04648
        19.5438 0.377811 18.8512C0.125937 18.7252 0
        18.5678 0 18.2845C0 16.3324 0 14.3489 0 12.3969C0
        11.8931 0.22039 11.7357 0.692654 11.9246C2.32984 12.6173
        3.96702 13.3414 5.63568 14.0341C5.91904 14.16 6.01349 14.3174
        6.01349 14.6323C6.01349 15.6083 6.01349 16.5843 6.01349 17.5603Z"
    />
    <path
      d="M6.39135 13.3729C6.2969 13.3414
      6.17096 13.31 6.07651 13.247C4.34487
      12.4914 2.61324 11.7043 0.881605
      10.9486C0.6927 10.8542 0.535278 10.7597
       0.535278 10.5079C0.535278 10.2875 0.6927
        10.193 0.881605 10.0986C2.64472 9.31145
         4.37636 8.52435 6.13948 7.76872C6.2969
          7.70575 6.54877 7.70575 6.70619 7.76872C8.46931
           8.52435 10.2009 9.31145 11.9641 10.0671C12.153
            10.1615 12.3104 10.256 12.3104 10.4764C12.3104
            10.6968 12.153 10.8227 11.9641 10.8857C10.2009
             11.6728 8.46931 12.4599 6.70619 13.2155C6.58026
              13.31 6.4858 13.3414 6.39135 13.3729Z"
    />
    <path
      d="M9.63422 3.42391H7.71368C7.65071 3.42391
       7.61923 3.4554 7.61923 3.51837V5.8482C7.61923
       6.13156 7.36735 6.38343 7.084 6.38343H5.69869C5.41533
       6.38343 5.16346 6.13156 5.16346 5.8482V3.51837C5.16346
        3.4554 5.13197 3.42391 5.069 3.42391H3.14846C3.05401
        3.42391 3.02253 3.32946 3.08549 3.26649C4.15596 2.19603
        5.25791 1.09408 6.32837 0.0236132C6.35986 -0.00787106 6.42283
        -0.00787106 6.45431 0.0236132C7.49329 1.06259 8.59524 2.16454 9.69719
        3.23501C9.76016 3.32946 9.72867 3.42391 9.63422 3.42391Z"
    />
  </svg>

</template>

<script>
import iconMixin from './iconMixin';

export default {
  mixins: [iconMixin],
  name: 'PackageUp',
};
</script>

<style scoped>

</style>
