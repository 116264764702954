<template>
  <div>
    <div class="avatar-circle d-flex align-center justify-center"
         :style="{ width: size + 'px', height: size + 'px' }">
      {{ firstLetter }}
    </div>
  </div>
</template>

<script>
import user from '../../mixins/user';

export default {
  name: 'Avatar',
  mixins: [user],
  props: {
    size: {
      required: false,
      default: 32,
    },
    name: {
      required: true,
    },
  },
  computed: {
    firstLetter() {
      return this.name?.charAt(0)?.toUpperCase();
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-circle {
  font-weight: bold;
  background: var(--v-green-base);
  color: #fff;
  border-radius: 50%;
  user-select: none;
  text-transform: uppercase;
}
</style>
