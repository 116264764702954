<template>
  <VRow>
    <VCol cols="5">
      <VCard max-width="300">
        <VCardText>
          <div class="info-img" v-if="!newItem.path_img">
            <img
              :src="require('@/assets/images/product-desc.png')"
              alt=""
              style="height: 100%;width:100%;object-fit:cover"
            />
          </div>
          <div class="info-img" v-else>
            <img class="br-10" :src="newItem.path_img" alt="" />
          </div>
          <VRow>
            <VCol cols="5" class="ccol">
              <VCol class="ccol">{{$t('table.name')}} :</VCol>
              <VCol class="ccol">{{$t('table.ns_code')}} :</VCol>
            </VCol>
            <VCol cols="1" class="ccol">
              <VDivider vertical />
            </VCol>
            <VCol cols="6" class="ccol">
              <VCol class="ccol"> {{ newItem.name || "--" }} </VCol>
              <VCol class="ccol"> {{ newItem.bar_code || "--" }} </VCol>
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
    </VCol>
    <VCol cols="7">
      <VRow justify="center">
        <VCol cols="7">
          <VBtn class="ma-auto" text color="primary">
            <v-icon left>
              mdi-information-outline
            </v-icon>
           {{$t('history.infoAboutItem')}}
          </VBtn>
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="4">
          <VCol v-if="user">{{$t('form.client')}} :</VCol>
          <VCol v-if="user">{{$t('form.phone_number')}} :</VCol>
          <VCol v-if="user">{{$t('form.email')}} :</VCol>
          <VCol>{{$t('form.sector')}} :</VCol>
          <VCol>{{$t('form.cell')}} :</VCol>
        </VCol>

        <VCol cols="1">
          <VDivider vertical />
        </VCol>
        <VCol cols="6">
          <VCol class="pt-0" v-if="user">
            <v-text-field
              class="pt-0 my-0"
              hide-details
              dense
              solo
              readonly
              :value="user.name ? `${user.name + ' ' + user.surname}` : '--'"
            ></v-text-field>
          </VCol>
          <VCol class="pt-0" v-if="user">
            <v-text-field
              class="py-0 my-0"
              hide-details
              dense
              solo
              readonly
              :value="user.phone ? user.phone : '--'"
            ></v-text-field>
          </VCol>
          <VCol class="pt-0" v-if="user">
            <v-text-field
              class="py-0 my-0"
              hide-details
              dense
              solo
              readonly
              :value="user.email ? user.email : '--'"
            ></v-text-field>
          </VCol>
          <VCol class="pt-0">
            <v-text-field
              class="p-0 my-0"
              hide-details
              dense
              solo
              readonly
              :value="sector ? sector : '--'"
            ></v-text-field>
          </VCol>
          <VCol class="pt-0">
            <v-text-field
              class="py-0 my-0"
              hide-details
              dense
              solo
              readonly
              :value="cell ? cell : '--'"
            ></v-text-field>
          </VCol>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
export default {
  data: () => ({
    newItem: []
  }),
  props: {
    item: {
      require: true
    },
    sector: {
      require: true
    },
    user: {
      require: true
    },
    cell: {
      require: true
    }
  },
  watch: {
    item: {
      deep: true,
      handler(e) {
        this.newItem = e;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.info-img {
  margin-bottom: 1rem;
  width: 100%;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.ccol {
  width: auto;
  flex-grow: unset;
  flex-basis: unset;
  padding-right: 0;
}
</style>
