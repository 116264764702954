<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.718 18.5H15.532C16.0715 18.5 16.4065 18.0722 16.0515
       17.6659C15.6525 17.2093 15.1278 16.7236 15.1014 16.2174C14.8265
        10.9622 14.4071 3.5 8.62498 3.5C2.84285 3.5 2.42345 10.9622 2.14864
        16.2174C2.12217 16.7236 1.59749 17.2093 1.1985 17.6659C0.843495 18.0722
        1.17848 18.5 1.718 18.5Z" stroke="#F8F8F8" stroke-width="2"/>
    <path d="M8.62497 21.5C9.68634 21.5 10.255 21.122
     10.5364 20.6953C10.8235 20.2601 10.3724 19.82 9.85098 19.82H7.39896C6.87758
     19.82 6.42641 20.2601 6.7135 20.6953C6.99494 21.122 7.56361 21.5 8.62497 21.5Z"
          fill="#F8F8F8"/>
    <ellipse cx="8.62502" cy="1.34" rx="1.07637" ry="0.84" fill="#F8F8F8"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin';

export default {
  mixins: [iconMixin],
  name: 'Bell',
};
</script>

<style scoped>

</style>
