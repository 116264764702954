import { v4 as uuidv4 } from 'uuid';
import * as actionTypes from './types/actions';
import * as mutationTypes from './types/mutations';

export default {
  [actionTypes.SET_SUCCESS_NOTIFICATION]: ({ commit }, text) => {
    commit(mutationTypes.SET_NOTIFICATION, {
      id: uuidv4(),
      text,
      type: 'success',
      visible: true,
    });
  },
  [actionTypes.SET_INFO_NOTIFICATION]: ({ commit }, text) => {
    commit(mutationTypes.SET_NOTIFICATION, {
      id: uuidv4(),
      text,
      type: 'info',
      visible: true,
    });
  },
  [actionTypes.SET_ERROR_NOTIFICATION]: ({ commit }, text) => {
    commit(mutationTypes.SET_NOTIFICATION, {
      id: uuidv4(),
      text,
      type: 'error',
      visible: true,
    });
  },
  [actionTypes.CLOSE_NOTIFICATION]: ({ commit }, notificationId) => {
    commit(mutationTypes.CLOSE_NOTIFICATION, notificationId);
  },
};
