<template>
  <VNavigationDrawer
    fixed
    v-model="miniMob"
    absolute
    style="top: 56px; height: calc(100vh - 56px);"
    :class="{ fixed: fixed }"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :mini-variant="!mini"
  >
    <v-divider />
    <VList nav dense>
      <VListItemGroup active-class="active-green--text text--accent-4">
        <div v-for="(list, key) in listData" :key="key">
          <div v-if="'nested_data' in list">
            <VListGroup
              class="listGroupMenu"
              v-if="list.permission"
              :value="list.value"
              active-class="active-green--text"
              color="var(--v-green-base)"
              @click="navigateTo(list.navigate_name)"
            >
              <template v-slot:activator>
                <VListItemIcon>
                  <VIcon>{{ list.activator_data.icon }}</VIcon>
                </VListItemIcon>

                <VListItemTitle>{{ list.activator_data.title }}</VListItemTitle>
              </template>

              <div v-for="(nested_data, key) in list.nested_data" :key="key">
                <VListItem
                  :to="$localize({ name: nested_data.navigate_name })"
                  v-if="nested_data.permission"
                >
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <VListItemIcon v-bind="attrs" v-on="on"
                        ><VIcon>{{ nested_data.icon }}</VIcon></VListItemIcon
                      >
                    </template>
                    {{ nested_data.title }}
                  </v-tooltip>
                  <VListItemTitle>{{ nested_data.title }}</VListItemTitle>
                </VListItem>
              </div>
            </VListGroup>
          </div>
          <div v-else>
            <VListItem
              :class="list.class"
              :to="$localize({ name: list.navigate_name, params: list.navigate_params })"
              v-if="list.permission"
              exact
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <VListItemIcon v-bind="attrs" v-on="on"
                    >
                    <ns-badge v-if="list.badge && list.icon && list.navigate_name != 'join-requests'" overlap :ns-content="list.badge" :ns-color="list.badgeColor" ns-size="xs">
                      <VIcon >{{ list.icon }}</VIcon>
                    </ns-badge>
                    <ns-badge v-if="list.icon && list.navigate_name == 'join-requests' && amount != '0'" overlap :ns-content="amount" ns-color="orange darken-4" ns-size="xs">
                      <VIcon >{{ list.icon }}</VIcon>
                    </ns-badge>
                    <VIcon v-if="list.icon && !list.badge && list.navigate_name == 'join-requests' && amount == '0'"  >{{ list.icon }}</VIcon>
                    <VIcon v-if="list.icon && !list.badge && list.navigate_name != 'join-requests'"  >{{ list.icon }}</VIcon>
                    <package-out-icon v-else-if="list.navigate_name === 'supply'" />
                    <move-all-icon v-else-if="list.navigate_name === 'move-list'" />
                    <payment-plans-icon v-else-if="list.navigate_name === 'plans-list'" />
                  </VListItemIcon>
                </template>
                {{ list.title }}
              </v-tooltip>
              <VListItemTitle>{{ list.title }}</VListItemTitle>
            </VListItem>
          </div>
        </div>

      </VListItemGroup>
    </VList>
  </VNavigationDrawer>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as getterTypes from "@/store/modules/auth/types/getters";
import lang from "@/mixins/language";
import user from "../../mixins/user";
import * as mutations from "@/store/modules/auth/types/mutations";
import * as actionTypes from "@/store/modules/auth/types/actions";
import navigationDraver from './navigationDrawer'
import newRequests from '@/mixins/newRequestsMixin'

export default {
  name: "LeftMenu",
  data: () => ({
    fixed: false,
    mini: false,
    miniMob: false,
    usersSelector: false,
    depotsSelector: false,
    userSettings: false,
    docsSelector: false,
    listData: [],
    docsList: [],
  }),
  methods: {
    ...mapActions("auth", {
      fetchUserData: actionTypes.FETCH_USER_DATA
    }),
    navigateTo(routeName) {
      if (!routeName) return;
      this.$router.push({ name: routeName }).catch(() => {});
    },
    fetchData() {
      if (!this.$vuetify.breakpoint.mdAndUp) {
        this.mini = true;
        this.miniMob = this.menuPosition;
      } else {
        this.mini = this.menuPosition;
        this.miniMob = false;
      }
      // console.log('this.menuPosition', this.menuPosition)
      // console.log('this.mini', this.mini)
    }
  },
  async mounted() {
    await this.fetchData()

    if (this.permissions.can_view_join_request) {
      await this.fetchAmount()
    }
  },
  computed: {
    ...mapGetters("auth", {
      menuPosition: getterTypes.GET_MENU_POSITION
    }),
    ...mapGetters(["currentLanguage"])
  },
  watch: {
    currentLanguage: {
      handler() {
        this.renderMenu()
      }
    },
    miniMob(e) {
      this.$store.commit(`auth/${mutations.SET_MENU_POSITION}`, e);
    },
    menuPosition: "fetchData"
  },
  mixins: [lang, user,navigationDraver, newRequests]
};
</script>
<style lang="scss"></style>
