import * as mutations from './types/mutations';
import {
  CityNormalizer,
  WarehouseNormalizer,
} from './normalizer';

export default {
  [mutations.SET_CITIES]: (state, cities) => {
    state.cities = cities.map((cityObj) => CityNormalizer(cityObj));
  },
  [mutations.SET_WAREHOUSES]: (state, warehouses) => {
    state.warehouses = warehouses.map((warehouseObj) => WarehouseNormalizer(warehouseObj));
  },
  [mutations.DELAY_WAREHOUSES]: (state) => {
    state.warehouses = [];
  },
};
