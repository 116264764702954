import Vue from "vue";
import VueRouter from "vue-router";

// Guard
// eslint-disable-next-line import/no-cycle
import store from "@/store";
import * as actionTypes from "@/store/modules/auth/types/actions";
import LoginGuard from "../components/guard/LoginGuard.vue";
// eslint-disable-next-line import/no-cycle
import authService from "../services/request/auth/authService";

Vue.use(VueRouter);

const checkPermission = ({name}) => {
  const permissionsPage = store.getters["auth/getPermissions"].pages;
  // const permissionsPage = {cabinet:true,'nested-data':false,crm:true}
  const pages = Object.keys(permissionsPage);
  const allowedPages = pages.filter(page => permissionsPage[page]);
  const isPermission = Boolean(allowedPages.find(page => page === name));
  console.log(name, isPermission,allowedPages[0]);
  return [isPermission,allowedPages[0]]
};

const isAuthenticated = async (to, from, next) => {
  if (authService.getToken()) {
    if (!store.state.auth.user) {
      await store.dispatch(`auth/${actionTypes.FETCH_USER_DATA}`);
    }
    return next()
    // const [isPermission,allowedPage] = checkPermission(to)
    // if (isPermission) {
    //   return next();
    //   } else if (to.next != allowedPage) {
    //     // eslint-disable-next-line no-unused-expressions
    //     alert('Sorry, you don\'t have the right access to reach the route requested')
    //     return false
    //   }
  } else {
    if (to.name == 'documents') {
      next({name: 'landing-documents', params: {lang: to.params.lang, uuid: to.params.uuid}})
    }
    else next("/login");
  }
};
const isNotAuthenticated = (to, from, next) => {
  console.log(authService.getToken());
  if (!authService.getToken()) {
    next();
    return;
  }
  next({ name: "cabinet" });
};

const routes = [
  {
    path: "/:lang?/register",
    name: "register",
    beforeEnter: isNotAuthenticated,
    component: () => import("../views/Register.vue"),
    meta: { title: 'NewSend - Ваш Онлайн Склад - Реєстрація' }
    // meta: { requiresAuth: false },
  },
  {
    path: "/:lang?/login",
    name: "login",
    beforeEnter: isNotAuthenticated,
    component: () => import("../views/Login.vue"),
    meta: { title: 'NewSend - Ваш Онлайн Склад - Авторизація' }
    // meta: { requiresAuth: false },
  },
  {
    path: "/:lang?/landing",
    name: "landing",
    component: () => import("../views/landing/Landing.vue"),
    meta: { title: 'NewSend - Ваш Онлайн Склад - Вітання' }

    // meta: { requiresAuth: false },
  },
  {
    path: "/:lang?/documents/:uuid",
    name: "landing-documents",
    component: () => import("../views/documents/DocumentsLanding.vue"),
    meta: { title: 'NewSend - Ваш Онлайн Склад - Вітання' }

    // meta: { requiresAuth: false },
  },
  {
    path: "/:lang?/activation/:hash",
    name: "activation",
    beforeEnter: isNotAuthenticated,
    component: () => import("../views/Activation")
    // meta: { requiresAuth: false },
  },
  {
    path: "/:lang?/password/new/:hash",
    name: "resetPassword",
    beforeEnter: isNotAuthenticated,
    component: () => import("../views/ResetPassword.vue")
    // meta: { requiresAuth: false },
  },
  {
    path: "",
    component: LoginGuard,
    beforeEnter:isAuthenticated,
    children: [
      {
        path: "/:lang?/",
        name: "cabinet",
        component: () => import("../views/Cabinet.vue"),
      },
      {
        path: "/:lang?/addresses",
        name: "addresses",
        meta: { title: 'NewSend - Ваш Онлайн Склад - Адреси' },
        component: () => import("../views/Addresses.vue")
      },
      {
        path: "/:lang?/move-list",
        name: "move-list",
        meta: { title: 'NewSend - Ваш Онлайн Склад - Список переміщень' },
        component: () => import("../views/MoveList.vue")
      },
      {
        path: "/:lang?/payment-plans-list",
        name: "plans-list",
        meta: { title: 'NewSend - Ваш Онлайн Склад - Тарифні плани' },
        component: () => import("../views/PlansList.vue")
      },
      {
        path: "/:lang?/join-requests",
        name: "join-requests",
        meta: { title: 'NewSend - Ваш Онлайн Склад - Запити' },
        component: () => import("../views/JoinRequests.vue")
      },
      {
        path: "/:lang?/supply",
        name: "supply",
        meta: { title: 'NewSend - Ваш Онлайн Склад - Відправки' },
        component: () => import("../views/Supply.vue")
      },
      {
        path: "/:lang?/crm",
        name: "crm",
        meta: { title: 'NewSend - Ваш Онлайн Склад - CRM' },
        component: () => import("../views/CRM.vue")
      },
      {
        path: "/:lang?/prices",
        name: "prices",
        meta: { title: 'NewSend - Ваш Онлайн Склад - Ціни' },
        component: () => import("../views/prices/Prices.vue")
      },
      {
        path: "/:lang?/",
        component: () => import("../views/Depots.vue"),
        children: [
          {
            path: "depots",
            name: "depots",
            meta: { title: 'NewSend - Ваш Онлайн Склад - Склади' },
            component: () => import("../views/depots/Depots.vue")
          },
          {
            path: "cells",
            name: "cells",
            meta: { title: 'NewSend - Ваш Онлайн Склад - Комірки' },
            component: () => import("../views/depots/Cells.vue")
          },
          {
            path: "sectors",
            name: "sectors",
            meta: { title: 'NewSend - Ваш Онлайн Склад - Сектори' },
            component: () => import("../views/depots/Sectors.vue")
          },
          {
            path: "products",
            name: "products",
            meta: { title: 'NewSend - Ваш Онлайн Склад - Товари' },
            component: () => import("../views/depots/Products.vue")
          },
          {
            path: "nested-data",
            name: "nested-data",
            meta: { title: 'NewSend - Ваш Онлайн Склад - Склад' },
            component: () => import("../views/depots/NestedDataDashboard.vue"),
          }
        ]
      },
      {
        path: "/:lang?/products/bar-code/:barCode",
        name: "products-bar-code",
        component: () => import("../views/depots/ProductsByBarCode.vue")
      },
      {
        path: "/:lang?/catalog",
        name: "catalog",
        meta: { title: 'NewSend - Ваш Онлайн Склад - Каталог товарів' },
        component: () => import("../views/Catalog")
      },
      {
        path: "/:lang?/call-center",
        name: "call-center",
        meta: { title: 'NewSend - Ваш Онлайн Склад - Дзвінки' },
        component: () => import("../views/CallCenter.vue")
      },
      {
        path: "/:lang?/users",
        name: "users",
        component: () => import("../views/Users.vue"),
        children: [
          {
            path: "clients",
            name: "clients",
            meta: { title: 'NewSend - Ваш Онлайн Склад - Клієнти' },
            component: () => import("../views/users/Clients.vue")
          },
          {
            path: "crm-users",
            name: "crm-users",
            meta: { title: 'NewSend - Ваш Онлайн Склад - CRM-користувачі' },
            component: () => import("../views/users/Crm.vue")
          },
          {
            path: "managers",
            name: "managers",
            meta: { title: 'NewSend - Ваш Онлайн Склад - Менеджери' },
            component: () => import("../views/users/Managers.vue")
          },
          {
            path: "employees",
            name: "employees",
            meta: { title: 'NewSend - Ваш Онлайн Склад - Працівники' },
            component: () => import("../views/users/Employees.vue")
          },
          {
            path: "admins",
            name: "admins",
            meta: { title: 'NewSend - Ваш Онлайн Склад - Адміністратори' },
            component: () => import("../views/users/Admins.vue")
          },
          {
            path: "owners",
            name: "owners",
            meta: { title: 'NewSend - Ваш Онлайн Склад - Власники' },
            component: () => import("../views/users/Owners.vue")
          }
        ]
      },
      {
        path: "/:lang?/profile",
        name: "profile",
        meta: { title: 'NewSend - Ваш Онлайн Склад - Профіль' },
        component: () => import("../views/profile/profile")
      },
      {
        path: "/:lang?/panel/documents/:uuid",
        name: "documents",
        meta: { title: 'NewSend - Ваш Онлайн Склад - Документація' },
        component: () => import("../views/documents/Documents.vue")
      }
    ]
  },
  {
    path: "/:lang?/market/:id",
    name: "market",
    component: () => import("../views/market/Market.vue")
  },
  {
    path: "/:lang?/supply/details/:id/:type",
    name: "supply-details",
    component: () => import("../views/SupplyDetails.vue")
  },

  // {
  //   path: '/:lang?/dashboard',
  //   name: 'dashboard',
  //   component: () => import('../views/dashboard/Dashboard.vue'),
  // },
  // {
  //   path: '*',
  //   component: () => import('../views/404'),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    return next({ name: "cabinet" });
  } else {
    if(authService.getToken()){
      const permissionsPage = store.getters["auth/getPermissions"].pages;
      console.log('permissionsPage', permissionsPage[to.name])
      if(permissionsPage[to.name]){
        next()
      } else {
        const pages = Object.keys(permissionsPage);
        const allowedPages = pages.filter(page => permissionsPage[page]);
        next({ name: allowedPages[0] })
      }
    } else {
      next()
    }
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
      document.title = to.meta.title || 'NewSend - Ваш Онлайн Склад';
  });
});

export default router;
